import * as $ from "jquery";

$(function () {
    if (location.hash === "#emailSignIn") {
        showEmail($("#loginWithEmail"));
    }
    $("#loginWithEmail").click(function () {
        showEmail(this);
    });
    $("#loginWithSocial").click(function () {
        showSocial(this);
    });
});
function showSocial(el) {
    $(".email-login-form").hide();
    $(".social-buttons").show();
    $("#loginDivider").show();
    $(el).hide();
    $("#loginWithEmail").show();
    $("#createAccountEmail").show();
}
function showEmail(el) {
    $(".email-login-form").show();
    $(".social-buttons").hide();
    $("#loginDivider").hide();
    $("#createAccountEmail").hide();
    $(el).hide();
    $("#loginWithSocial").show();
}
