import Alert from '../../../../node_modules/bootstrap/js/src/alert'
// import Button from '../../../../node_modules/bootstrap/js/src/button'
//import Carousel from '../../../../node_modules/bootstrap/js/src/carousel'
import Collapse from '../../../../node_modules/bootstrap/js/src/collapse'
import Dropdown from '../../../../node_modules/bootstrap/js/src/dropdown'
import Modal from '../../../../node_modules/bootstrap/js/src/modal'
import Popover from '../../../../node_modules/bootstrap/js/src/popover'
//import Scrollspy from '../../../../node_modules/bootstrap/js/src/scrollspy'
//import Tab from '../../../../node_modules/bootstrap/js/src/tab'
// import Tooltip from '../../../../node_modules/bootstrap/js/src/tooltip'
import Util from '../../../../node_modules/bootstrap/js/src/util'

export {
    Util,
    Alert,
    // Button,
    // Carousel,
    Collapse,
    Dropdown,
    Modal,
    Popover,
    // Scrollspy,
    // Tab,
    // Tooltip
}

$(function () {
    (<any>$("[data-toggle='popover']")).popover({
        trigger: "manual",
        html: true,
        animation: false
    })
        .on("mouseenter", function () {
            var _this = this;
            (<any>$(this)).popover("show");
            (<any>$(".popover")).on("mouseleave", function () {
                (<any>$(_this)).popover("hide");
            });
        }).on("mouseleave", function () {
            var _this = this;
            setTimeout(function () {
                if (!$(".popover:hover").length) {
                    (<any>$(_this)).popover("hide");
                }
            }, 300);
    });

    (<any>$(".alert")).fadeTo(7500, 500).slideUp(500, function () {
        (<any>$(".alert")).alert("close");
        (<any>$(this)).parents(".row").remove();
    });
    if(window.location.hash) {
        var hash = window.location.hash;
        try {
            if ($(hash).hasClass("modal")) {
                (<any>$(hash)).modal("toggle");
            }
        } catch (e) {
            console.log("Hash " + hash + "is invalid.");
        }
    }
});
