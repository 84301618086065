// LIVE FORM VALIDATION
var LiveValidation = require("live-form-validation-es6");
(<any>window).Nette = LiveValidation.Nette;
(<any>window).LiveForm = LiveValidation.LiveForm;
//set options
(<any>window).LiveForm.setOptions({
    messageErrorPrefix: "",
    //    controlValidClass: "valid",
    controlErrorClass: "invalid",
    messageErrorClass: "caption message error",
    messageParentClass: "has-error",
    showMessageClassOnParent: "input",
    messageTag: "ul",
});
//overwrite getMessageElement
(<any>window).LiveForm.getMessageElement = function (el) {
    // For multi elements (with same name) work only with first element attributes
    if (el.name && el.name.match(/\[\]$/)) {
        el = el.form.elements[el.name].tagName ? el : el.form.elements[el.name][0];
    }
    var id = el.getAttribute("data-lfv-message-id");
    if (!id) {
        // ID is not specified yet, let's create a new one
        id = this.getMessageId(el);
        // Remember this id for next use
        el.setAttribute("data-lfv-message-id", id);
    }
    var messageEl = document.getElementById(id);
    if (!messageEl) {
        var parentEl = this.getMessageParent(el);
        if (el.type.toLowerCase() == "hidden") {
            messageEl = document.createElement("div");
            messageEl.id = id;
        }
        else {
            // Message element doesn't exist, lets create a new one
            messageEl = document.createElement(this.options.messageTag);
            messageEl.id = id;
        }
        if (el.style.display == "none" && !this.hasClass(el, this.options.enableHiddenMessageClass)) {
            messageEl.style.display = "none";
        }
        if (parentEl) {
            parentEl.appendChild(messageEl);
        }
    }
    return messageEl;
};
(<any>window).LiveForm.addError = function (el, message) {
    // Ignore elements with disabled live validation
    if (this.hasClass(el, this.options.disableLiveValidationClass))
        return;
    var groupEl = this.getGroupElement(el);
    this.setFormProperty(el.form, "hasError", true);
    this.addClass(groupEl, this.options.controlErrorClass);
    if (this.options.showValid) {
        this.removeClass(groupEl, this.options.controlValidClass);
    }
    if (!message) {
        message = "&nbsp;";
    }
    else {
        message = this.options.messageErrorPrefix + message;
    }
    // Do not show message for checkbox and radio
    if (el.type) {
        var type = el.type.toLowerCase();
        if (type == "checkbox" || type == "radio") {
            return;
        }
    }
    var messageEl = this.getMessageElement(el);
    messageEl.className = this.options.messageErrorClass;
    messageEl.innerHTML = "<li>" + message + "</li>";
};
(<any>window).Nette.initOnLoad();
