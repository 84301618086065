(function (document, Nette, grecaptcha) {
    var init = false;

    Nette.recaptcha = function (grecaptcha) {
        var items = document.getElementsByClassName("g-recaptcha");
        var length = items.length;
        if (length > 0) {
            if (grecaptcha) {
                grecaptcha.ready(function () {
                    for (var i = 0; i < length; i++) {
                        grecaptcha.render(items[i]);
                    }

                });
                $(".g-recaptcha").parent().find("[type=submit]").on("click", function (e) {
                    e.preventDefault();
                    // grecaptcha.reset();
                    grecaptcha.execute();
                });
                init = true;
            }
        }
    };
    if (!init) {
        Nette.recaptcha(grecaptcha);
    }
})(document, (<any>window).Nette, (<any>window).grecaptcha);
(<any>window).onSubmit = function() {
    $(".g-recaptcha").parent().submit();
};