"use strict";
exports.__esModule = true;
require("jquery-cropper");
$(function () {
    function initCropper(selector) {
        var $container = $(selector);
        if (!$container.length)
            return;
        var $inputImage = $("[data-cropper-input-image]", $container);
        var $image = $("[data-cropper-image]", $container);
        var $form = $image.closest("form");
        var $cropper = $image.closest(".cropper");

        (<any>$image).cropper({
            aspectRatio: $image.data("aspect-ratio"),
            viewMode: $image.data("view-mode"),
            dragMode: "move",
            toggleDragModeOnDblclick: false,
            minContainerHeight: 300,
            minCropBoxWidth: 128,
            minCropBoxHeight: 128,
            crop: function (e) {
                $cropper.find("[data-cropper-x]").val(e.detail.x);
                $cropper.find("[data-cropper-y]").val(e.detail.y);
                $cropper.find("[data-cropper-w]").val(e.detail.width);
                $cropper.find("[data-cropper-h]").val(e.detail.height);
            }
        });
        $container.on("hide.bs.modal", function () {
            (<any>$image).cropper("destroy");
            (<any>$form[0]).reset();
        });
        // Import image
        var URL = window.URL;
        var blobURL;
        if (URL) {
            $inputImage.change(function () {
                if (!$image.data("cropper")) {
                    return;
                }
                var files = (<any>this).files;
                var file;
                if (files && files.length) {
                    file = files[0];
                    if (/^image\/\w+$/.test(file.type)) {
                        blobURL = URL.createObjectURL(file);
                        $image.one("built.cropper", function () {
                            URL.revokeObjectURL(blobURL);
                        });
                        (<any>$image).cropper("reset")
                            .cropper("replace", blobURL);
                        $image.removeClass("d-none");
                    }
                    else {
                        (<any>$form[0]).reset();
                        window.alert($inputImage.data("cropper-file-type-error") || "Invalid file type.");
                    }
                }
            });
        }
        else {
            $inputImage.prop("disabled", true);
        }
    }
    
    $(".image-upload").on("show.bs.modal", function () {
        initCropper("#"+$(this).attr("id"));
    });
});
