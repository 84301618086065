"use strict";
exports.__esModule = true;
import * as $ from 'jquery';
(<any>window).jQuery = $;
(<any>window).$ = $;


require("../global/life-form-validation.ts");
require("../global/raven.ts");
require("../global/cropper.ts");
require("./bootstrap.ts");
require("../global/recaptcha.ts");

$(function () {
    $(document).scroll(function () {
        var $nav = $(".navbar");
        $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
    });
    $(".overlay-close").click(function () {
        $(this).parents(".overlay-message").remove();
    });
});
require("../global/components/login-form.ts");
